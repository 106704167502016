import '../css/Contact.css'
import { useState } from "react";
import { useForm } from "react-hook-form";
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

export default function Contact() {
    const [responseMsg, setResponseMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" });

    const messageOptions = {
        full_name: {
            required: "Full name is required",
            minLength: {
                value: 3,
                message: "Full name must be at least 3 characters"
            },
            maxLength: {
                value: 30,
                message: "Full name cannot exceed 30 characters"
            },
            pattern: {
                value: /^[a-zA-Z\s]+$/,
                message: "Full name should only contain letters"
            }
        },
        email: {
            required: "Email is required",
            pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|net)$/,
                message: "Email must be a valid .com or .net address"
            }
        },
        contact_message: {
            required: "Message is required",
            minLength: {
                value: 3,
                message: "Message must be at least 3 characters"
            },
            // pattern: {
            //     value: /^[a-zA-Z\s]+$/,
            //     message: "Full name should only contain letters"
            // }
        }
    };

    const handleVerifyEmail = async (formData) => {
        setIsLoading(true);
        const { full_name, email, contact_message } = formData;
        try {
            const response = await fetch('/api/send-message', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ full_name, email, contact_message })
            });
            const data = await response.json();
            setResponseMsg(data.message)
        } catch (error) {
            // do something
        }
        setIsLoading(false);
    };

    return (
        <div className="contact-div">
            <div className="contact-message-block">
                <h2 className="contact-title" style={{fontSize: '15px', textAlign: 'center'}}>MESSAGE</h2>
                <div className="message-text-boxes">
                    <form method='POST' onSubmit={handleSubmit(handleVerifyEmail)}>
                        <div className="fullname-div text-div">
                            <label style={{display: 'block'}}>Full name</label>
                            <input 
                                style={{width: '100%'}} 
                                placeholder='Your name'
                                {...register("full_name", messageOptions.full_name)}
                            >
                            </input>
                            <small style={{color: 'red'}}>
                                {errors?.full_name && errors.full_name.message}
                            </small>
                        </div>
                        <div className="email-div text-div">
                            <label style={{display: 'block'}}>Email</label>
                            <input 
                                style={{width: '100%'}} 
                                placeholder='Email' 
                                type='email' 
                                {...register("email", messageOptions.email)}
                            >
                            </input>
                            <small style={{color: 'red'}}>
                                {errors?.email && errors.email.message}
                            </small>
                        </div>
                        <div className="message-textarea text-div">
                            <label style={{display: 'block'}}>Message</label>
                            <textarea 
                                placeholder='Your message' 
                                style={{height: '100px', resize: 'none'}} 
                                name="message" 
                                rows="4" 
                                cols="56"
                                {...register("contact_message", messageOptions.contact_message)}
                            >
                            </textarea>
                            <small style={{color: 'red'}}>
                                {errors?.contact_message && errors.contact_message.message}
                            </small>
                        </div>
                        <div style={{display: 'flex'}}>
                            <button type='submit'>Submit</button>
                            <CircularProgress size="30px" style={{marginLeft: '20px', color:'white', display: isLoading ? 'block' : 'none'}}/>
                        </div>
                        <div style={{marginTop: '15px'}}>
                            <small style={{color: 'white'}}>{responseMsg}</small>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}